"use strict";
import { defineComponent as _defineComponent } from "vue";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex flex-col h-screen w-full" };
const _hoisted_2 = { class: "container flex flex-col h-full p-4 sm:p-20" };
const _hoisted_3 = { class: "flex items-center justify-center mx-auto" };
const _hoisted_4 = { class: "h-[calc(100%-20px)] flex flex-col justify-center items-center" };
const _hoisted_5 = { class: "text-white font-bold text-3xl text-center" };
const _hoisted_6 = { class: "text-white text-xl text-center my-4 max-w-xl" };
const _hoisted_7 = { class: "text-center" };
const _hoisted_8 = { class: "font-semibold" };
export default /* @__PURE__ */ _defineComponent({
  __name: "maintenance",
  setup(__props) {
    false && {};
    return (_ctx, _cache) => {
      const _component_AssetLogo = _resolveComponent("AssetLogo");
      const _component_AssetLogoText = _resolveComponent("AssetLogoText");
      const _component_AppButton = _resolveComponent("AppButton");
      const _component_NuxtLink = _resolveComponent("NuxtLink");
      return _openBlock(), _createElementBlock("main", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_AssetLogo, {
              class: "h-10 w-10 mr-2",
              alt: "Helix"
            }),
            _createVNode(_component_AssetLogoText, { class: "h-8 text-white" })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.$t("maintenance_header")), 1),
            _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t("maintenance_body")), 1),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_NuxtLink, {
                to: "https://status.exchange.injective.network/",
                target: "_blank"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AppButton, {
                    xl: "",
                    class: "rounded-full text-blue-900 bg-blue-500"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("maintenance_button")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ])
        ])
      ]);
    };
  }
});
